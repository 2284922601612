import React, { useEffect, useState, useRef, useMemo } from 'react';
import Export from './Export';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { postAPI } from './service.js/Api.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select, { components, InputActionMeta } from 'react-select';
import Count from "./Count";
import { getAllByLabelText } from '@testing-library/react';
import { Modal, Button } from "react-bootstrap";
import { useOktaAuth } from '@okta/okta-react';
// import { DebounceInput } from 'react-debounce-input';
import Swal from "sweetalert2";
import * as myConstants from './Constants';

function Home() {
    const [countWell, setcountWell] = useState(0);
    const { oktaAuth, authState } = useOktaAuth();
    const [showLogin, setShowLogin] = useState(false);
    const [isDis, setisDis] = useState(false);
    const [isDisUpload, setisDisUpload] = useState(false);
    const isLength = useRef(true);
    const isLengthUp = useRef(true);
    const isSpace = useRef('');
    const [loaded, setLoaded] = useState(true);
    const MoreSelectedBadge = ({ items }) => {
        const style = {
            marginLeft: "auto",
            borderRadius: "4px",
            fontSize: "16px",
            padding: "3px",
            order: 99
        };

        const title = items.join(", ");
        const length = items.length;
        const label = ` ${length}  more...`;

        return (
            <div style={style} title={title}>
                {label}
            </div>
        );
    };
    const MultiValue = ({ index, getValue, ...props }) => {
        const maxToShow = 10;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);

        return index < maxToShow ? (
            <components.MultiValue {...props} />
        ) : index === maxToShow ? (
            <MoreSelectedBadge items={overflow} />
        ) : null;
    };
    const [state, setvalue] = useState({
        value: 0,
        conutyValue: 0,
        totalCountOfState: 0,
        totalCountOfCounty: 0,
        isCountylist: [],
        removeDup: [],
        getsameCounty: [],

    });
    const [count, setcount] = useState({
        wellcount: 0,
        getWellCount: 0,
        stateWellCount: 0,
        countyWellCount: 0,

        wellProductionCount: 0,
        stateProductionCount: 0,
        countyProductionCount: 0,
        getProductionCount: 0,

        wellActiveProducingCount: 0,
        statewellActiveProducingCount: 0,
        countywellActiveProducingCount: 0,
        getwellActiveProducingCount: 0,

        slantSideTrackCount: 0,
        stateslantSideTrackCount: 0,
        countyslantSideTrackCount: 0,
        getslantSideTrackCount: 0,

        slantDirectionalCount: 0,
        stateslantDirectionalCount: 0,
        countyslantDirectionalCount: 0,
        getslantDirectionalCount: 0,

        slantVerticalCount: 0,
        stateslantVerticalCount: 0,
        countyslantVerticalCount: 0,
        getslantVerticalCount: 0,

        slantHorizontalCount: 0,
        stateslantHorizontalCount: 0,
        countyslantHorizontalCount: 0,
        getslantHorizontalCount: 0,

        unkowncount: 0,
        stateUnknownCount: 0,
        countyUnknownCount: 0,
        getUnknownCount: 0,

        isselectedStaCountCount: 0,
        isSelectedCountyTotal: 0,
        isProductionCount: 0,
        selectedStateCount: 0,
        countget: 0,

        stateHighlightStyles: {
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                color: "#222",
                '&:hover': { backgroundColor: '#f5faff' }
            })
        },
        countyHighlightStyles: {
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                color: "#222",
                '&:hover': { backgroundColor: '#f5faff' }
            })
        }
    });

    const [isFileData, setisFileData] = React.useState("");

    const prevCountRef = useRef(count.countyWellCount);
    const prevCountProd = useRef(count.countyProductionCount);
    const prevCountActiveProd = useRef(count.countywellActiveProducingCount);
    const prevCountSlantside = useRef(count.countyslantSideTrackCount);
    const prevCountSlantDirect = useRef(count.countyslantDirectionalCount);
    const prevCountSlantVertical = useRef(count.countyslantVerticalCount);
    const prevCountSlantHorizontal = useRef(count.countyslantHorizontalCount);
    const [selectedStateOption, setSelectedStateOption] = useState([]);
    const [selectedCountyOption, setSelectedCountyOption] = useState([]);
    const [allCountyOptions, setAllCountyOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [countyOptions, setCountyOptions] = useState([]);
    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
    const sortIt = sortBy => (a, b) => {
        if (a[sortBy] > b[sortBy]) {
            return 1;
        } else if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        return 0;
    }

    async function isListofStatesAndCounty() {
        setLoaded(true);
        let statebody = {

            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Field": "WellActiveProducingCount",
                    "NotEqualOperator": false,
                    "Value": 0
                },
                {
                    "Field": "Country",
                    "EqualOperator": false,
                    "Value": "UNITED STATES OF AMERICA"
                }
            ],
            "Aggregations": [
                {
                    "AggregateBy": "State"
                }
            ],
            "From": 0,
            "Size": 0

        }
        let countyBody = {
            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Query": "WellActiveProducingCount: >0",
                    "NotEqualOperator": false,
                    "Value": 0
                },
                {
                    "Field": "Country",
                    "EqualOperator": true,
                    "Value": "UNITED STATES OF AMERICA"
                }
            ],
            "IncludeFields": ["Country", "State", "StateCode", "StateCounty"],
            "From": 0,
            "Size": 5000
        }

        const getDataForState = await postAPI(statebody, oktaAuth.getAccessToken());
        const isListOfStatesresponse = getDataForState.data.Aggregations;
        setLoaded(false);
        const totalCountOfState = isListOfStatesresponse.length;
        const sortedByState = isListOfStatesresponse.sort(sortIt('Key'));
        setStateOptions(sortedByState);
        let stateValue = [];
        for (let d = 0; d < isListOfStatesresponse.length; d++) {
            stateValue.push(isListOfStatesresponse[d].Key);
        }
        setLoaded(true);
        const getDateForCounty = await postAPI(countyBody, oktaAuth.getAccessToken());
        setLoaded(false);
        const isListOfCountyresponse = getDateForCounty.data.Documents;

        const totalCountOfCounty = (addCommas(removeNonNumeric(isListOfCountyresponse.length)));
        const sortedByCounty = isListOfCountyresponse.sort(sortIt('StateCounty'));
        setCountyOptions(sortedByCounty);
        setAllCountyOptions(sortedByCounty);
        isOnLoadStateCount(stateValue);
        setvalue({ ...state, totalCountOfCounty, totalCountOfState });

    }
    const isSelectCounty = (c) => {
        if (selectedStateOption.length == 0 && c.length == 0) {
            isListofStatesAndCounty();
        }
        if (c.length > selectedCountyOption.length) {
            setSelectedCountyOption(c);
            if (c.length == 0 && selectedStateOption.length > 0) {
                isStateSelected(selectedStateOption, c)

            } else if (c.length > 0 && selectedStateOption.length == 0) {
                isCountySelected(c, selectedStateOption);
            } else if (c.length > 0 && selectedStateOption.length > 0) {

                isCountySelected(c, selectedStateOption);
                // isStateSelected(selectedStateOption, c);
            }
            else {
                isCountySelected(c, selectedStateOption);
                // isStateSelected(selectedStateOption, c)
            }

        } else {
            isCountyClear(c)
        }

    }
    const isSelectState = (e, action) => {

        if (action.action == 'clear') {

            const clearedCounties = selectedCountyOption.filter((item) => !selectedStateOption.some((re) => item.State == re.value));

            setSelectedStateOption(e);
            setSelectedCountyOption(clearedCounties);
            if (clearedCounties.length > 0) {
                isCountySelected(clearedCounties, e);
            } else {
                isListofStatesAndCounty();
            }
            // setSelectedStateOption(e);
            // isCountySelected(selectedCountyOption, e);
        } else {
            if (e.length == 0 && selectedCountyOption.length == 0) {
                isListofStatesAndCounty();
            }
            if (e.length > selectedStateOption.length) {
                const filteredCountiies = allCountyOptions.filter((item) => e.some((re) => item.State == re.value));
                setCountyOptions(filteredCountiies);
                setSelectedStateOption(e);

                if (e.length > 0 && selectedCountyOption.length > 0) {

                    isCountySelected(selectedCountyOption, e);
                    // isStateSelected(e, selectedCountyOption);
                }
                else if (e.length > 0 && selectedCountyOption.length == 0) {
                    isStateSelected(e, selectedCountyOption);
                }
                else {
                    // isStateSelected(e, selectedCountyOption);
                }

            } else {
                setSelectedStateOption(e);
                const filteredSelCountiies11 = allCountyOptions.filter((item) => e.some((re) => item.State == re.value));
                setCountyOptions(filteredSelCountiies11);
                isStateClear(e);
            }
        }
    }
    async function isOnLoadStateCount(states) {
        setLoaded(true);
        let ipBody = {
            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Field": "State",
                    "Values": states,
                    "NotEqualOperator": false
                }
            ],
            "Aggregations": [
                {
                    "AggregateBy": "State",
                    "PropertyName": "WellCount",
                    "AggregationTypes": [
                        "Sum"
                    ]
                }
            ],
            "From": 0,
            "Size": 10000
        }
        const isDataStateCount = await postAPI(ipBody, oktaAuth.getAccessToken());
        setLoaded(false);
        const isDataResponse = isDataStateCount.data.Documents;
        let wellcount = 0;
        let wellProductionCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let unkowncount = 0;
        for (let i = 0; i < isDataResponse.length; i++) {
            wellcount += isDataResponse[i].WellCount;
            wellProductionCount += isDataResponse[i].WellProductionCount;
            wellActiveProducingCount += isDataResponse[i].WellActiveProducingCount;
            slantSideTrackCount += isDataResponse[i].SlantSideTrackCount;
            slantDirectionalCount += isDataResponse[i].SlantDirectionalCount;
            slantVerticalCount += isDataResponse[i].SlantVerticalCount;
            slantHorizontalCount += isDataResponse[i].SlantHorizontalCount;

        }
        unkowncount = wellcount - slantVerticalCount - slantSideTrackCount - slantDirectionalCount - slantHorizontalCount;
        setcount({ ...count, wellcount, wellProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, unkowncount })

    }

    function sessionKill() {
        Swal.fire({
            icon: 'info',
            html: '<h5>Your session has expired.</h5>' +
                'Click OK to return to the Login page.',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconColor: '#11375f'
        }).then((result) => {
            if (result.isConfirmed) {
                oktaAuth.signOut();
            }
        })
    }

    useEffect(() => {
        let currentTime = new Date();
        let expirationTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), myConstants.setHrs, myConstants.setMins, myConstants.setSecs, 0);
        let millisTillExpiration = expirationTime.getTime() - currentTime.getTime();
        setTimeout(() => { sessionKill(); }, millisTillExpiration);

        oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
            console.log('Token with key', key, ' has expired:');
            if (key == 'accessToken') {
                sessionKill();
            } else {
            }
        });
        //assign the ref's current value to the count Hook
        prevCountRef.current = count.countyWellCount;
        prevCountProd.current = count.countyProductionCount;
        prevCountActiveProd.current = count.countywellActiveProducingCount;
        prevCountSlantside.current = count.countyslantSideTrackCount;
        prevCountSlantDirect.current = count.countyslantDirectionalCount;
        prevCountSlantHorizontal.current = count.countyslantHorizontalCount;
        prevCountSlantVertical.current = count.countyslantVerticalCount;
    }, [count.countyWellCount, count.countyProductionCount, count.countywellActiveProducingCount, count.countyslantSideTrackCount,
    count.countyslantDirectionalCount, count.countyslantHorizontalCount, count.countyslantVerticalCount]);

    async function isStateSelected(paramstate, paramCounty) {
        setSelectedStateOption(paramstate);
        setSelectedCountyOption(paramCounty);
        let statesToQuery = [];
        let statesFromSelectedCounties = [];
        let countiesToQUery = [];
        let stateHighlightStyles = {};
        stateHighlightStyles = {
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                color: "#222",
                '&:hover': { backgroundColor: '#f5faff' }
            })
        };
        let countyHighlightStyles = {};
        for (let county of paramCounty) {
            statesFromSelectedCounties.push(county["State"]);
            countiesToQUery.push(county["value"].substring(5));
        }
        statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
        for (let j = 0; j < paramstate.length; j++) {
            statesToQuery.push(paramstate[j].value);
        }
        statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));
        setLoaded(true);
        let ipBody = {
            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Field": "State",
                    "Values": statesToQuery,
                    "NotEqualOperator": false
                }
            ],
            "Aggregations": [
                {
                    "AggregateBy": "State",
                    "PropertyName": "WellCount",
                    "AggregationTypes": [
                        "Sum"
                    ]
                }
            ],
            "From": 0,
            "Size": 10000
        }
        const responseData = await postAPI(ipBody, oktaAuth.getAccessToken());
        setLoaded(false);
        const isSelectedState = responseData.data.Documents;
        const isSelectedCountyCount = responseData.data.Aggregations;
        let isselectedStaCountCount = 0;
        let isSelectedCountyTotal = 0;
        let selectedStateCount = 0;
        let stateWellCount = 0;
        let stateProductionCount = 0;
        let statewellActiveProducingCount = 0;
        let stateslantSideTrackCount = 0;
        let stateslantDirectionalCount = 0;
        let stateslantHorizontalCount = 0;
        let stateslantVerticalCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let stateUnknownCount = 0;
        let getWellCount = 0;
        let getProductionCount = 0;
        let getwellActiveProducingCount = 0;
        let getslantSideTrackCount = 0;
        let getslantDirectionalCount = 0;
        let getslantHorizontalCount = 0;
        let getslantVerticalCount = 0;
        let getUnknownCount = 0;
        // setcombineCount(combineCount)
        let passQuery = [];

        for (let jx = 0; jx < statesToQuery.length; jx++) {
            for (let ix = 0; ix < isSelectedState.length; ix++) {
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].WellCount) {
                    stateWellCount += isSelectedState[ix].WellCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].WellProductionCount) {
                    stateProductionCount += isSelectedState[ix].WellProductionCount;

                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].WellActiveProducingCount) {
                    statewellActiveProducingCount += isSelectedState[ix].WellActiveProducingCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantSideTrackCount) {
                    stateslantSideTrackCount += isSelectedState[ix].SlantSideTrackCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantDirectionalCount) {
                    stateslantDirectionalCount += isSelectedState[ix].SlantDirectionalCount;

                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantVerticalCount) {
                    stateslantVerticalCount += isSelectedState[ix].SlantVerticalCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantHorizontalCount) {
                    stateslantHorizontalCount += isSelectedState[ix].SlantHorizontalCount;
                }

            }
        }
        for (let p = 0; p < isSelectedCountyCount.length; p++) {
            isselectedStaCountCount += isSelectedCountyCount[p].Value;
        }

        for (let oe = 0; oe < statesToQuery.length; oe++) {
            passQuery = statesToQuery[oe];

        }
        if (statesToQuery.length > 0 && paramCounty.length == 0) {

            isSelectedCountyTotal = isselectedStaCountCount;
            stateWellCount = stateWellCount;
            stateProductionCount = stateProductionCount;
            statewellActiveProducingCount = statewellActiveProducingCount;
            stateslantSideTrackCount = stateslantSideTrackCount;
            stateslantDirectionalCount = stateslantDirectionalCount;
            stateslantHorizontalCount = stateslantHorizontalCount;
            stateslantVerticalCount = stateslantVerticalCount;

            stateHighlightStyles = {
                option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                    color: "#222",
                    '&:hover': { backgroundColor: '#f5faff' }
                }),
                multiValueLabel: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "bold",
                    backgroundColor: "#FFF"
                })
            };

        }
        else if (paramstate.length == 0 && paramCounty.length > 0) {
            isSelectedCountyTotal = countiesToQUery.length;
        }
        else {
            isSelectedCountyTotal = isselectedStaCountCount + countiesToQUery.length;
            getWellCount = stateWellCount + prevCountRef.current;
            getProductionCount = stateProductionCount + prevCountProd.current;
            getwellActiveProducingCount = statewellActiveProducingCount + prevCountActiveProd.current;
            getslantSideTrackCount = stateslantSideTrackCount + prevCountSlantside.current;
            getslantDirectionalCount = stateslantDirectionalCount + prevCountSlantDirect.current;
            getslantHorizontalCount = stateslantHorizontalCount + prevCountSlantHorizontal.current;
            getslantVerticalCount = stateslantVerticalCount + prevCountSlantVertical.current;
            getUnknownCount = getWellCount - getslantVerticalCount - getslantSideTrackCount - getslantDirectionalCount - getslantHorizontalCount;
            stateHighlightStyles = {
                option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                    color: "#222",
                    '&:hover': { backgroundColor: '#f5faff' }
                }),
                multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: paramCounty.some((pc) => data.value == pc.State) ? "normal" : "bold",
                    backgroundColor: "#FFF"
                })
            };
        }
        selectedStateCount = statesToQuery.length + statesFromSelectedCounties.length;
        stateUnknownCount = stateWellCount - stateslantVerticalCount - stateslantSideTrackCount - stateslantDirectionalCount - stateslantHorizontalCount;
        setcount({ ...count, stateHighlightStyles, stateslantDirectionalCount, stateslantHorizontalCount, stateslantSideTrackCount, stateslantVerticalCount, selectedStateCount, getWellCount, statewellActiveProducingCount, getUnknownCount, getProductionCount, getwellActiveProducingCount, getslantSideTrackCount, getslantDirectionalCount, getslantHorizontalCount, getslantVerticalCount, isSelectedCountyTotal, isselectedStaCountCount, stateWellCount, getWellCount, stateProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, stateUnknownCount })
    }
    async function isCountySelected(getCounty, paramstate) {
        setSelectedStateOption(paramstate);
        setSelectedCountyOption(getCounty);
        let statesToQuery = []
        let countiesToQUery = [];
        let statesFromSelectedCounties = [];
        let stateHighlightStyles = {};
        stateHighlightStyles = {
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                color: "#222",
                '&:hover': { backgroundColor: '#f5faff' }
            })
        };
        let countyHighlightStyles = {};
        countyHighlightStyles = {
            option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                color: "#222",
                '&:hover': { backgroundColor: '#f5faff' }
            }),
        }
        for (let county of getCounty) {
            statesFromSelectedCounties.push(county["State"]);
            countiesToQUery.push(county["value"].substring(5));
        }
        statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
        countiesToQUery = [... new Set(countiesToQUery)];
        for (let j = 0; j < paramstate.length; j++) {
            statesToQuery.push(paramstate[j].value);
        }
        statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));
        setLoaded(true);
        let isCountylist = [];
        for (let h = 0; h < getCounty.length; h++) {
            isCountylist.push(getCounty[h].value);
        }
        let body = {};
        if (getCounty.length >= 1 && paramstate.length == 0) {
            body = {
                "SearchType": "WpdProductCounts",
                "SearchFieldValues": [
                    {
                        "Field": "StateCounty",
                        "NotEqualOperator": false,
                        "Values": isCountylist
                    }
                ],
                "Aggregations": [
                    {
                        "AggregateBy": "Country",
                        "OrderBy": "Value",
                        "Order": "asc",
                        "PropertyName": "WellCount",
                        "AggregationTypes": [
                            "Sum"
                        ]
                    },
                    {
                        "AggregateBy": "Country",
                        "OrderBy": "Value",
                        "Order": "asc",
                        "PropertyName": "WellActiveProducingCount",
                        "AggregationTypes": [
                            "Sum"
                        ]
                    }
                ],
                "From": 0,
                "Size": 5000
            }
        } else {
            body = {
                "SearchType": "WpdProductCounts",
                "SearchFieldValues": [
                    {
                        "Field": "County",
                        "NotEqualOperator": false,
                        "Value": null,
                        "Values": countiesToQUery
                    },
                    {
                        "Field": "State",
                        "Values": statesFromSelectedCounties,
                        "NotEqualOperator": false
                    }
                ],
                "Aggregations": [
                    {
                        "AggregateBy": "State",
                        "PropertyName": "WellCount",
                        "AggregationTypes": [
                            "Sum"
                        ]
                    }
                ],
                "From": 0,
                "Size": 10000
            }
        }
        const responseData = await postAPI(body, oktaAuth.getAccessToken());
        const selectedCountyData = responseData.data.Documents;
        setLoaded(false);
        let countyWellCount = 0;
        let countyProductionCount = 0;
        let countywellActiveProducingCount = 0;
        let countyslantDirectionalCount = 0;
        let countyslantHorizontalCount = 0;
        let countyslantSideTrackCount = 0;
        let countyslantVerticalCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let countyUnknownCount = 0;
        let removeDup = [];
        let isselectedStaCountCount = 0;
        let getWellCount = 0;
        let getProductionCount = 0;
        let getwellActiveProducingCount = 0;
        let getslantSideTrackCount = 0;
        let getslantDirectionalCount = 0;
        let getslantHorizontalCount = 0;
        let getslantVerticalCount = 0;
        let getUnknownCount = 0;
        for (let zex = 0; zex < getCounty.length; zex++) {
            removeDup.push(getCounty[zex].State);
            removeDup = [... new Set(removeDup)];
        }
        for (let z = 0; z < isCountylist.length; z++) {
            for (let s = 0; s < selectedCountyData.length; s++) {
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyWellCount += selectedCountyData[s].WellCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyProductionCount += selectedCountyData[s].WellProductionCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countywellActiveProducingCount += selectedCountyData[s].WellActiveProducingCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantSideTrackCount += selectedCountyData[s].SlantSideTrackCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantDirectionalCount += selectedCountyData[s].SlantDirectionalCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantVerticalCount += selectedCountyData[s].SlantVerticalCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantHorizontalCount += selectedCountyData[s].SlantHorizontalCount;
                }
            }
            countyUnknownCount = countyWellCount - countyslantVerticalCount - countyslantSideTrackCount - countyslantDirectionalCount - countyslantHorizontalCount;
        }
        let passCountyQuery = [];
        if (countiesToQUery.length > 0 && statesToQuery.length > 0) {
            isStateSelected(paramstate, getCounty);
        } else if (statesFromSelectedCounties.length > 0) {
            count.selectedStateCount = statesFromSelectedCounties.length;
            count.isSelectedCountyTotal = countiesToQUery.length;
            getWellCount = countyWellCount;
            getProductionCount = countyProductionCount;
            getwellActiveProducingCount = countywellActiveProducingCount;
            getslantSideTrackCount = countyslantSideTrackCount;
            getslantDirectionalCount = countyslantDirectionalCount;
            getslantHorizontalCount = countyslantHorizontalCount;
            getslantVerticalCount = countyslantVerticalCount;
            getslantSideTrackCount = countyslantSideTrackCount;
            getUnknownCount = getWellCount - getslantVerticalCount - getslantSideTrackCount - getslantDirectionalCount - getslantHorizontalCount;

            for (let oe = 0; oe < countiesToQUery.length; oe++) {
                passCountyQuery = countiesToQUery[oe];
                countyHighlightStyles = {
                    option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected ? "#ebf5ff" : "null",
                        color: "#222",
                        '&:hover': { backgroundColor: '#f5faff' }
                    }),
                    multiValueLabel: (passCountyQuery) => ({
                        ...passCountyQuery,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        backgroundColor: '#FFF'
                    }),
                }
            }

        }
        setcount({
            ...count, stateHighlightStyles, countyHighlightStyles, getUnknownCount, isselectedStaCountCount, getProductionCount, getwellActiveProducingCount, getslantSideTrackCount, getslantDirectionalCount, getslantHorizontalCount,
            getslantVerticalCount, countyWellCount, countyProductionCount, countywellActiveProducingCount, countyslantSideTrackCount, countyslantDirectionalCount, countyslantHorizontalCount, countyslantVerticalCount, getWellCount, countyProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, countyUnknownCount
        })
        setvalue({ ...state, removeDup });

    }
    function openWindow() {
        var win = window.open('https://export-dev.datalake.tgs.com', '_blank');
        setTimeout(function () {
            win.postMessage({
                "application": "Saga Analytics",
                "userName": "Robin Tan",
                "userEmail": "Robin.Tan@tgs.com",
                "productTypes": [
                    "WellProductionData",

                ],
                "searchCriteria": {
                    "uwis": ["42001000010000", "42001000030000", "42001000310000"]
                },
                "accessToken": oktaAuth.getAccessToken()
            }, "*")
        }, 1000);

        win.focus();
    }
    function isClear() {
        let statesToQuery = [];
        let statesFromSelectedCounties = [];
        let countiesToQUery = [];
        setSelectedStateOption([]);
        setSelectedCountyOption([]);

        // setStateOptions(stateOptions);
        setCountyOptions(allCountyOptions);
        isListofStatesAndCounty();
        setvalue({ ...state, statesToQuery, statesFromSelectedCounties, countiesToQUery });
        setisDis(false);
        setError("");
        setisFileData("");
        setIsUwiSearchList([]);
        isLength.current = true;
        const file = document.querySelector('#upload');
        file.value = '';
        setcountWell(0)
    }
    const isCountyClear = (removedItem) => {
        setSelectedCountyOption(removedItem);

        if (removedItem.length == 0) {

            isStateSelected(selectedStateOption, removedItem);
        } else {
            isCountySelected(removedItem, selectedStateOption);
        }
        if (selectedStateOption.length == 0 && removedItem.length == 0) {
            isListofStatesAndCounty();
        }


    }


    const isStateClear = (removedItem) => {
        console.log('TEST');
        const removeValue = selectedStateOption.filter(e => !removedItem.includes(e));
        const filteredSelCountiies11 = selectedCountyOption.filter(item => removedItem.some(ri => item.State == ri.value));

        if (removeValue.length > 0 && filteredSelCountiies11.length == 0 && selectedStateOption.length > 0) {
            isStateSelected(removedItem, selectedCountyOption);
        } else if (selectedCountyOption.length > 0 && selectedStateOption.length > 0) {
            isCountySelected(selectedCountyOption, removedItem)
        }
        if (removedItem.length === 0 && selectedStateOption.length <= 2) {
            const filteredSelCountiies1 = selectedCountyOption.filter(item => selectedStateOption.some(ri => item.State != ri.value));
            setSelectedCountyOption(filteredSelCountiies1);


        } else if (removedItem.length > 0 && selectedStateOption.length > 2) {
            const diff = selectedStateOption.filter(e => !removedItem.includes(e));
            const filteredSelCountiies2 = selectedCountyOption.filter(item => diff.some(e => item.State != e.value));
            setSelectedCountyOption(filteredSelCountiies2);
        }
        else {
            const remArr = selectedStateOption.filter(a => removedItem.some(r => a.value != r.value));
            const filteredSelCountiies3 = selectedCountyOption.filter(item => remArr.some(e => item.State != e.value));
            setSelectedCountyOption(filteredSelCountiies3);
        }
        if (removedItem.length === 0) {
            setCountyOptions(allCountyOptions);
        }

        if (removedItem.length == 0) {
            isListofStatesAndCounty();
        }

    }

    const onChangeFile = (e) => {

        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            const specialChar = /[\!\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\/\\\\]/g;
            var lengtharr = e.target.result.split(/[^a-zA-Z0-9\!\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\/\\\\]+/g).filter(v => v).join('\n');
            var finalList = lengtharr.split('\n');

            if (!specialChar.test(e.target.result)) {
                isLength.current = false;
                setisDis(false);
                setError("")
            }
            else {
                isLength.current = true;
                setisDis(true);
                setError("UWI’s must be between 10-14 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
            }

            finalList.map(valList => {
                if (valList.trim().length < 10 || valList.trim().length > 14 || /\s/.test(valList)) {

                    isLength.current = true;
                    setisDis(true);
                    setError("UWI’s must be between 10-14 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
                } else {
                    isLength.current = false;
                    // setisDis(false);
                }
            })
            finalList = [...new Set(finalList)];
            var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            finalList = finalList.filter(valListItem => (valListItem.trim().length >= 10 && valListItem.trim().length <= 14 && !format.test(valListItem.trim())));
            setcountWell(finalList.length)
            setisFileData(lengtharr);

        };

        reader.readAsText(file);

    }
    const [error, setError] = useState("");

    const onChangeTextInFile = (e) => {
        if (e.target.value == '') {
            setcountWell(0);
            const file = document.querySelector('#upload');
            file.value = '';
        }
        const re = /[\!\\\\/\\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
        let old_val = isFileData;
        var new_str = e.target.value.split('').map((str, i) => {
            if (old_val[i] !== str) {
                return { val: str, position: i }
            }
        }).filter(val => val !== undefined)[0];
        let newArr = (new_str && ((new_str.position == e.target.value.length - 1) && new_str.val == ',')) ? true : false;
        if (newArr) {
            let newArr1 = e.target.value.split('');
            newArr1.splice(new_str.position, 1, '\n');
            let newV = newArr1.join('');
            e.target.value = newV;
            setisFileData(newV)
        }
        let str2 = e.target.value
        let str4 = str2.split('').map((row, i) => {
            if (str2[i - 1] == ' ' && row != ' ') return '\n' + row.trim()
            return row;
        })
        if (!re.test(e.target.value)) {
            isLength.current = false;
            setisDis(false);
            setError("")
        }
        else {

            isLength.current = true;
            setisDis(true);
            setError("UWI’s must be between 10-14 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
        }
        let str3 = str4.join('').split('\n');
        str3 = str3.filter(entry => entry.trim() != '');

        str3.map(string => {

            if (string.trim().length < 10 || string.trim().length > 14) {
                isLength.current = true;
                setisDis(true);
                setError("UWI’s must be between 10-14 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
            }
        })
        str3 = [...new Set(str3)];
        var format = /[ `\/!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        str3 = str3.filter(valListItems => (valListItems.trim().length >= 10 && valListItems.trim().length <= 14 && !format.test(valListItems.trim())));
        setcountWell(str3.length)
        setisFileData(str4.join(''));

    }
    const [isUwiSearchList, setIsUwiSearchList] = useState([]);
    function isUwiSearch() {
        const dataInFile = isFileData.split('\n');
        setIsUwiSearchList(dataInFile);
    }
    // async function isUwiSearch() {
    //     const dataInFile = isFileData.split('\n');
    //     setIsUwiSearchList(dataInFile);
    //     setLoaded(true);
    //     let ipBody = {
    //         "SearchType": "WpdProductCounts",
    //         "SearchFieldValues": [
    //             {
    //                 "Field": "Uwi",
    //                 "Values": dataInFile
    //             }
    //         ],
    //         "Aggregations": [
    //             {
    //                 "AggregateBy": "Country",
    //                 "OrderBy": "Value",
    //                 "Order": "asc",
    //                 "PropertyName": "WellCount",
    //                 "AggregationTypes": [
    //                     "Sum"
    //                 ]
    //             },
    //             {
    //                 "AggregateBy": "Country",
    //                 "OrderBy": "Value",
    //                 "Order": "asc",
    //                 "PropertyName": "WellActiveProducingCount",
    //                 "AggregationTypes": [
    //                     "Sum"
    //                 ]
    //             }
    //         ],
    //         "From": 0,
    //         "Size": 5000
    //     }
    //     const isDataUwiCount = await postAPI(ipBody, oktaAuth.getAccessToken());
    //     setLoaded(false);
    //     console.log(isDataUwiCount);
    //     // const isUwiDataResponse = isDataUwiCount.data.Documents;


    // }
    const [selectedTab, setSelectedTab] = useState('HeaderSearch');

    const handleSelect = (e) => {
        setSelectedTab(e);
        setisDis(false);
        isLength.current = true;
    }

    useEffect(() => {
        isListofStatesAndCounty();
    }, []);

    return (
        <div className="page d-flex">
            <div className="col-md-1 searchCol" style={{ border: " 1px  solid #DCDCDC", height: '100vh' }}>
                {loaded && <div id="cover-spin"></div>}
                <div className="favIcons">
                    <ul className="list-group">
                        <li className="list-group-item">
                            <button className="btn btn-info btnCls">
                                <i className="fa fa-search" aria-hidden="true" style={{ color: 'white' }}></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="filterCol">
                <div id="textbox">
                    <h5 className="alignleft" style={{ color: '#FFA500' }}>Production Data</h5>
                    <h5><a className="alignright clrSearch cursorPointCls" onClick={isClear}>Clear Search</a></h5>

                </div>
                <br></br>
                <br></br>

                <br></br>
                <br></br>

                <Tabs
                    defaultActiveKey="HeaderSearch"
                    id="fill-tab-example"
                    onSelect={(e) => handleSelect(e)}
                    className="mb-2">
                    <Tab eventKey="HeaderSearch" title="Header Search">
                        <h6 style={{ display: 'flex' }}>Search by State and County</h6>

                        <Select isMulti closeMenuOnSelect={false}
                            value={selectedStateOption} rtl={false} hideSelectedOptions={false}
                            options={stateOptions.map(e => ({ label: e.Key, value: e.Key }))}
                            placeholder="Select State" components={{ MultiValue }}
                            onChange={(isSelectState)}
                            styles={count.stateHighlightStyles}
                        />
                        <br />
                        <Select isMulti closeMenuOnSelect={false} value={selectedCountyOption}
                            options={countyOptions.map(e => ({ label: e.StateCounty, value: e.StateCounty, State: e.State, id: e.Id }))}
                            placeholder="Select County" rtl={false} hideSelectedOptions={false} components={{ MultiValue }}
                            onChange={isSelectCounty}
                            styles={count.countyHighlightStyles}
                        />
                    </Tab>
                    <Tab eventKey="uwlList" title="UWI/API List">
                        <div className="tablebox">
                            <label><h6 style={{ fontSize: '14px' }}>UWI/API List <span style={{ color: '#ff0000' }}>&nbsp;*</span></h6> </label>
                            <textarea cols="35" rows="5" name="descr" value={isFileData} onChange={onChangeTextInFile} ></textarea><br />
                            {/*  <DebounceInput element="textarea" cols="35" rows="3"
                                value={isFileData}
                                onChange={onChangeTextInFile} /> */}
                            <p className='uwiErr mb-4'>{error}</p>
                            <label htmlFor="upload" className="uploadFile"><h6 style={{ marginTop: '-5px' }}>Or&nbsp;<i className="fa fa-upload" aria-hidden="true" style={{ marginTop: '4px' }}></i>&nbsp;Upload UWI/API List</h6></label>
                            <input type="file" name="photo" accept=".txt" id="upload" onChange={onChangeFile} />

                        </div>
                        {/* <Button variant="primary" disabled={isDis || isLength.current || isFileData.length == 0} className="btn btn-primary btn-sm m-3" onClick={isUwiSearch} style={{ width: '45%', float: 'right', fontSize: '16px' }}> Search </Button> */}
                    </Tab>

                </Tabs>

            </div>
            <div className="countCards">
                <div className="card-sec">
                    <div className="card">
                        <div className="card-header">
                            <b className="pull-left" style={{ color: '#FFA500' }}>
                                {selectedTab == 'HeaderSearch' ? (
                                    <span>Active Selection Counts</span>
                                ) : (
                                    <span>Active Selection Counts</span>
                                )}
                            </b>
                            
                            {selectedTab == 'HeaderSearch' ? (
                                <button className="btn btn-primary font-size22 pull-right" type="button" disabled={(selectedStateOption.length == 0 && selectedCountyOption.length == 0)} onClick={() => setShowLogin(true)}>Export</button>
                            ) : (
                                <button className="btn btn-primary font-size22 pull-right" type="button" disabled={isDis || isLength.current || isFileData.length == 0} onClick={() => { setShowLogin(true); isUwiSearch(); }}>Export</button>
                            )}

                            <Export show={showLogin} close={() => setShowLogin(false)} dataSelectedOption={selectedStateOption} dataSelectedCounty={selectedCountyOption}
                                dataUWiList={isUwiSearchList} dataSelectedTab={selectedTab} />

                        </div>
                        {selectedTab == 'HeaderSearch' ? (<div>
                            <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Region</h6>
                            <div className="container-fluid px-6">
                                <div className="row gx-4">
                                    <div className="col">
                                        <div className="p-3 border bg-light shadow">
                                            <div className="">
                                                <div className="text-center">
                                                    {(selectedStateOption.length > 0 &&
                                                        selectedCountyOption.length > 0) ? <h5 className="card-title">
                                                        {count.selectedStateCount}</h5> : (selectedStateOption.length > 0) ? <h5 className="card-title">
                                                            {selectedStateOption.length}
                                                        </h5> : (selectedCountyOption.length > 0) ? <h5 className="card-title">
                                                            {state.removeDup.length}
                                                        </h5> : <h5 className="card-title">
                                                        {state.totalCountOfState}
                                                    </h5>}
                                                    <p>State Count </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="p-3 border bg-light shadow">
                                            <div className="">
                                                <div className="text-center">
                                                    {(selectedStateOption.length > 0 && selectedCountyOption.length > 0) ?
                                                        <h5 className="card-title">{count.isSelectedCountyTotal}</h5>
                                                        : (selectedCountyOption.length > 0) ? <h5 className="card-title">
                                                            {selectedCountyOption.length}
                                                        </h5> : (selectedStateOption.length > 0) ? <h5 className="card-title">
                                                            {count.isselectedStaCountCount}
                                                        </h5> : <h5 className="card-title">
                                                            {state.totalCountOfCounty}
                                                        </h5>}

                                                </div>
                                                <p>County Count</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <Count data={count} data2={selectedStateOption} data3={selectedCountyOption} />
                            </div>
                        </div>) : (
                            <div className="container-fluid px-6">
                                <div className='row gx-4'>
                                    <div className="">
                                        <div className="p-3 border bg-light shadow uwicardInside ">
                                            <div className="text-center"><h5 className="card-title">{countWell.toLocaleString()}</h5><p>Well Count </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div >
    );
}

export default Home;
