import axios from 'axios';
// const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;

function postAPI(data, token) {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'x-api-key': process.env.REACT_APP_APIKEY,
    }
    const res = axios.post(process.env.REACT_APP_SEARCH_APIURL, data, {
        headers: headers
    }).catch((err) => {
        if (err.response) {
            console.log(err.response.status);
        }
    })
    return res;

}

function exportAPI(exportJson, token) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'x-api-key': process.env.REACT_APP_APIKEY,
    }
    const resExp = axios.post(process.env.REACT_APP_EXPORT_APIURL, exportJson, {
        headers: headers
    }).catch((err) => {
        if (err.response) {
            console.log(err.response.status);
        }
    })

    return resExp;
}
export { postAPI, exportAPI };