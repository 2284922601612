import React, { useEffect } from 'react';
import './App.css';
import Header from './Header';
import Home from './Home';
import Private from './Private';
import { Route } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import 'component-library/tgs.components.default.css'
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.REACT_APP_ISSUER;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
const SCOPES = process.env.REACT_APP_SCOPES;
const TITLE = process.env.REACT_APP_TITLE;

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URL,
  scopes: SCOPES ? SCOPES.split(' ') : '',
  pkce: true
};
const oktaAuth = new OktaAuth(config);

const App = () => {

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    document.title = TITLE;
  }, []);

  return (

    <div className="App">
      <div className="page">
        <div className="content">
          <Security oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
          >
            <Header />

            <SecureRoute path='/' exact={true} component={Home} />
            <Route path='/private' exact={true} component={Private} />
            <Route path='/callback' component={LoginCallback} />
          </Security>
        </div>
      </div>
    </div>
  );
}

export default App;