import React from "react";
import {
    Modal
} from "react-bootstrap";

import { Export, Schedule } from "component-library"

function DynamicModal(props) {
    return (
        <>

            <Modal show={props.show} size="lg" centered style={{ paddingLeft: '0px !important' }}>
                <Modal.Header >
                    <Modal.Title style={{ color: '#FFA500' }}>Export Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Export
                        title={"A Title Here"}
                        initialName={"foo"} initialDescription={"bar"} Additional={(options) => <Schedule {...options} />} onExport={(results) => { alert(JSON.stringify(results)); }} onCancel={function () { alert("Canceled"); }} initialFileFormats={[{
                            name: "297 Well Export", expanded: true, values: [{ name: "Common Delimited (97C)", value: false }, { name: "Common Delimited (97F)", value: false }
                            ]
                        }, {
                            name: "298 Production Export", expanded: false, values: [{ name: "Comma Delimited (98C)", value: false }, { name: "Fixed Field (98F)", value: false }
                            ]
                        }, {
                            name: "CSV", expanded: false, values: [{ name: "????", value: false }
                            ]
                        }
                        ]} />
                </Modal.Body>

            </Modal>
        </>
    );
}

export default DynamicModal;
